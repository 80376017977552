@font-face {
  font-family: 'Helvetica Now';
  src: local('Helvetica Now'), local('HelveticaNow'),
    url(../assets/fonts/HelveticaNowText-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Helvetica Now';
  src: local('Helvetica Now'), local('HelveticaNow'),
    url(../assets/fonts/HelveticaNowText-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Helvetica Now';
  src: local('Helvetica Now'), local('HelveticaNow'),
    url(../assets/fonts/HelveticaNowText-Bold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
